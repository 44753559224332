.painel-finalidade {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }
  
  @media (min-width: 768px) {
    .painel-finalidade {
      float: left;
      width: 50%;
    }
  }
  
  @media (max-width: 1199px) {
    .painel-finalidade {
      position: relative;
      min-height: 1px;
      padding-right: 15px;
      padding-left: 15px;
    }
  }
  
  @media (max-width: 1199px) and (min-width: 768px) {
    .painel-finalidade {
      float: left;
      width: 100%;
    }
  }
  
  @media (max-width: 991px) {
    .painel-finalidade {
      position: relative;
      min-height: 1px;
      padding-right: 15px;
      padding-left: 15px;
    }
  }
  
  @media (max-width: 991px) and (min-width: 768px) {
    .painel-finalidade {
      float: left;
      width: 100%;
    }
  }
  
  .painel-finalidade .painel {
    padding: 20px;
    background-color: #fff;
    text-align: center;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.1);
  }
  
  .painel-finalidade .painel h3 {
    font-size: 20px;
    color: #666;
  }
  
  .painel-finalidade .painel img {
    position: absolute;
    top: -60px;
    right: 0;
    left: 0;
    margin: 0 auto;
  }
  
  .painel-finalidade .painel .button-list .botao {
    padding: 5px 10px;
    background-color: #d2af1c;
    border: none;
    color: #fff;
    margin-right: 10px;
  }
  
  @media (max-width: 767px) {
    .painel-finalidade .painel .button-list .botao {
      margin-bottom: 15px;
    }
  }
  
  @media (max-width: 1199px) {
    .painel-finalidade .painel-bottom {
      margin-top: 60px;
    }
    .painel-finalidade .painel-bottom img {
      top: 0;
    }
  }
  
  @media (max-width: 767px) {
    .painel-finalidade .painel-bottom img {
      top: -60px;
    }
  }