#modalContatoImovel .modal-content {
    background-color: #d2af1c;
}

#modalContatoImovel .modal-content h2 {
    font-size: 20px;
}

#modalContatoImovel .modal-content .botao {
    background-color: #00264a;
}