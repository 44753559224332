.header-agradecimento .logomarca { 
    margin: 0 auto;   
}

.agradecimento {
    position: relative;
    height: auto;
    padding: 50px 0;
    background-image: url("./../../../assets/images/agradecimento.jpg");
    background-position: top center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}
  
.agradecimento .overlay {
    background-color: #00264a;
    filter: alpha(opacity=60);
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 101;
}
  
.agradecimento .wrap {
    position: relative;
    z-index: 201;
    color: #fff;
    padding: 60px 15px;
    text-align: center;
}
  
.agradecimento .wrap h1 {    
    margin: 0;
    font-size: 50px;
    font-weight: 400;
}
.agradecimento .wrap p {
    font-size: 20px;
    margin-top: 15px;
}

.link-imoveis-agradecimento{
   text-align: center;
   padding: 40px 10px;
}

.link-imoveis-agradecimento h2{
    font-size: 17px;
    font-weight: 400;
    color: #666;
    line-height: 22px;
    margin: 0 0 15px;
}

.link-imoveis-agradecimento .btn-secao-imoveis{
    padding: 10px 15px;
    background-color: #d2af1c;
    color: #fff;
    margin: 20px;
    display: inline-block;
    border-radius: 5px;
}