.bairros-destaque {
    position: relative;
    padding: 50px 0;
    background-image: url("./../../../assets/images/bg-bairros.jpg");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .bairros-destaque .overlay {
    background-color: #00264a;
    filter: alpha(opacity=60);
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 101;
  }
  
  .bairros-destaque .wrap {
    position: relative;
    z-index: 201;
    color: #fff;
    padding: 60px 15px;
  }
  
  .bairros-destaque .wrap h2 {
    margin: 0;
    font-size: 50px;
    font-weight: 300;
    text-align: center;
  }
  
  @media (max-width: 767px) {
    .bairros-destaque .wrap h2 {
      font-size: 40px;
    }
    .bairros-destaque .wrap h2 span {
      display: block;
      font-size: 16px;
    }
  }
  
  .link-form {
    margin-right: -15px;
    margin-left: -15px;
    padding: 20px 15px;
    background-color: #fff;
  }
  
  .link-form:before, .link-form:after {
    display: table;
    content: " ";
  }
  
  .link-form:after {
    clear: both;
  }
  
  .link-form .col {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    text-align: center;
  }
  
  @media (min-width: 768px) {
    .link-form .col {
      float: left;
      width: 50%;
    }
  }
  
  @media (min-width: 768px) {
    .link-form .col {
      margin-left: 25%;
    }
  }
  
  .link-form .col h3 {
    font-size: 16px;
    font-weight: 400;
    color: #666;
  }
  
  .link-form .col span {
    padding-top: 10px;
  }
  
  .link-form .col span .botao {
    background-color: #d2af1c;
    border: 0;
    color: #fff;
    padding: 10px;
  }
  
  @media (max-width: 1199px) {
    .link-form .col {
      position: relative;
      min-height: 1px;
      padding-right: 15px;
      padding-left: 15px;
    }
  }
  
  @media (max-width: 1199px) and (min-width: 768px) {
    .link-form .col {
      float: left;
      width: 100%;
    }
  }
  
  @media (max-width: 1199px) and (min-width: 768px) {
    .link-form .col {
      margin-left: 0%;
    }
  }
  
  .imoveis-da-regiao {
    background-color: #e3e3e3;
    padding: 30px 0;
  }
  
  @media (max-width: 767px) {
    .imoveis-da-regiao {
      padding: 10px 0 30px;
    }
  } 
  
  .painel-ajuda {
    background-color: #e3e3e3;
    text-align: center;
    padding-bottom: 20px;
  }
  
  .painel-ajuda h2 {
    font-size: 25px;
    color: #00264a;
  }
  
  .painel-ajuda .botao {
    background-color: #d2af1c;
    border: none;
    color: #fff;
    padding: 10px;
  }
  
  .imoveis-por-tipo {
    padding: 40px 0;
    background-color: #fff;
  }
  
  .imoveis-por-tipo .col {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }
  
  @media (min-width: 768px) {
    .imoveis-por-tipo .col {
      float: left;
      width: 50%;
    }
  }
  
  .imoveis-por-tipo .col h2 {
    font-size: 20px;
    color: #00264a;
  }
  
  .imoveis-por-tipo .col h2 span {
    color: #d2af1c;
  }
  
  .finalidade {
    background-color: #e3e3e3;
    padding: 75px 0 40px;
  }  