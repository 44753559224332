main {
    background-color: #f1f1f1;
}

.container-fluid {
    max-width: 1280px;
    padding: 40px 15px;
}

.col-valorInicial, .col-valorFinal,
.col-areaInicial, .col-areaFinal {
    position: relative;
    float: left;
    width: 50%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    padding: 0;
}

.col-areaInicial input, .col-areaFinal input {
    border: 1px solid #e3e3e3;
}

.col-areaInicial input:focus, .col-areaFinal input:focus {
    border-color: #e3e3e3;
}

.col-valorInicial, .col-areaInicial {
    padding-right: 5px;
}

.col-valorFinal, .col-areaFinal {
    padding-left: 5px;
}

input[type="radio"] {
    display: none;
}

input[type='checkbox'] {
    display: none;
}

.radio-inline {
    padding: 0;
}

.radio-inline span {
    background-color: #fff;
    border-radius: 30%;
    padding: 8px 10px;
    color: #666;
}

.radio-inline span:hover {
    background-color: #00264a;
    color: #fff;
}

.radio-inline input[type="radio"]:checked + span {
    background-color: #00264a;
    color: #fff;
}

.form-group {
    margin: 15px 0;
}

.form-control {
    font-size: 14px;
}

.sidebar {
    padding-left: 0 !important;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

@media (min-width: 768px) {
    .sidebar {
        float: left;
        width: 100%;
    }
}

@media (max-width: 991px) {
    .sidebar {
        position: fixed;
        width: 100%;
        height: 100%;
        max-width: 320px;
        top: 0;
        z-index: 3000;
        left: -350px;
        padding: 0;
        transition: left .3s;
        overflow-y: auto;
    }    
    .select2-container {
        z-index: 3000;
    }
}

.sidebar h2 {
    font-size: 15px;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 5px;
}

.sidebar .input-destaque, .sidebar .input-body {
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
}

.sidebar .input-destaque {
    background-color: #d2af1c;
    padding: 20px 10px 10px;
}

.sidebar .input-body {
    padding: 20px 10px 10px;
    background-color: #fff;
}

.sidebar .input-body h2 {
    color: #666;
    font-weight: 400 !important;
}

.sidebar .input-body .radio-inline span {
    background-color: #f1f1f1;
}

.sidebar .input-body .radio-inline span:hover {
    background-color: #00264a;
    color: #fff;
}

.sidebar .input-body .checkbox {
    clear: both;
}

.sidebar .input-body .checkbox label {
    line-height: 25px;
}

.sidebar .input-body .checkbox label:hover {
    color: #4b4000;
}

.sidebar .input-body .checkbox .ver-mais {
    padding: 10px 0;
    display: block;
    color: #d2af1c;
    font-weight: 500;
}

.sidebar .input-body .checkbox label .fa {
    border: 1px solid #a8abad;
    padding: 2px 3px;
    color: #fff;
    margin-right: 5px;
}

.sidebar .input-body .checkbox label input[type='checkbox']:checked + .fa {
    color: #00264a;
}

.sidebar .btn-filtro {
    display: none;
}

@media (max-width: 991px) {
    .sidebar .btn-filtro {
        display: block;
    }
}

.sidebar .btn-filtro .botao {
    background-color: #d2af1c;
    border: 0;
    color: #fff;
    width: 100%;
}

.sidebar .btn-close {
    color: #fff;
    padding: 10px;
    margin: 0;
    position: absolute;
    right: 0;
    font-size: 16px;
    display: none;
}

@media (max-width: 991px) {
    .sidebar .btn-close {
        display: block;
    }
}

.open-search:after {
    content: '';
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 38, 74, 0.6);
    z-index: 2000;
}

.open-search .sidebar {
    left: 0;
}

.content-resultado-de-busca {
    padding: 0 !important;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

@media (min-width: 768px) {
    .content-resultado-de-busca {
        /*float: left;*/
        width: 100%;
    }
}

@media (max-width: 991px) {
    .content-resultado-de-busca {
        position: relative;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
    }
}

@media (max-width: 991px) and (min-width: 768px) {
    .content-resultado-de-busca {
        /*float: left;*/
        width: 100%;
    }
}

.content-resultado-de-busca .header-content {
    background-color: #fff;
    padding: 15px;
}

.content-resultado-de-busca .header-content .list-tabs {
    display: flex;
    justify-content: space-between;
}

.content-resultado-de-busca .header-content .list-tabs .nav-itens ul li {
    display: inline-block;
    margin-right: 30px;
}

.content-resultado-de-busca .header-content .list-tabs .nav-itens ul li a {
    color: #00264a;
    font-size: 16px;
    font-weight: 500;
}

.content-resultado-de-busca .header-content .list-tabs .nav-itens ul li a:hover:after {
    width: 100%;
    background-color: #00264a;
}

.content-resultado-de-busca .header-content .list-tabs .nav-itens ul li a:after {
    content: "";
    display: table;
    margin: 5px auto 0;
    width: 0;
    height: 4px;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    background-color: #d2af1c;
}

.content-resultado-de-busca .header-content .list-tabs .nav-itens ul li .active:after {
    width: 100%;
}

.content-resultado-de-busca .header-content .list-tabs .nav-itens ul li .active:hover:after {
    background-color: #d2af1c;
}

.content-resultado-de-busca .header-content .btn-dropdown {
    outline: none;
    border: 1px solid #00264a;
}

.content-resultado-de-busca .header-content .btn-dropdown .form-control:hover,
.content-resultado-de-busca .header-content .btn-dropdown .form-control:focus {
    box-shadow: none;
}

@media (max-width: 991px) {
    .content-resultado-de-busca .header-content .list-tabs .btn-dropdown {
        display: none;
    }
}

.content-resultado-de-busca .header-content .search-info h1 {
    font-size: 25px;
}

.content-resultado-de-busca .header-content .search-info span {
    background-color: #f1f1f1;
    padding: 5px 10px;
    border-radius: 20px;
    margin: 0 10px 10px 0;
    display: inline-block;
}

@media (max-width: 991px) {
    .content-resultado-de-busca .header-content .search-info h1 {
        font-size: 18px;
    }
}

.content-resultado-de-busca .header-content .search-mobile {
    display: none;
    padding-top: 15px;
}

@media (max-width: 991px) {
    .content-resultado-de-busca .header-content .search-mobile {
        display: flex;
        justify-content: space-between;
    }
    .content-resultado-de-busca .header-content .botao {
        padding: 11px 15px !important;
        border-radius: 0;
        height: 49px;
    }
}

.content-resultado-de-busca .header-content .botao {
    padding: 5px 10px;
}

.content-resultado-de-busca .body-content .row{
    margin: 0;
}

.content-resultado-de-busca .body-content .cardImovel {
    margin: 20px 0;
}

.segment-sidebar-resultado-de-busca {
    float: left;
    width: 25%;
    margin-top: 0 !important;
    padding: 0 !important;
}

.segment-content-resultado-de-busca {
    float: left;
    width: 75%;
    margin-top: 0 !important;
    background-color: transparent !important;
    border: 0 !important;
    box-shadow: none !important; 
    padding: 0 !important;
}

@media (max-width: 991px) {
    .segment-content-resultado-de-busca  {
        width: 100% !important;
    }
}

.paginacao {
    margin-right: -15px;
    margin-left: -15px;
  }
  
  .paginacao:before, .paginacao:after {
    display: table;
    content: " ";
  }
  
  .paginacao:after {
    clear: both;
  }
  
  .paginacao nav {
    text-align: center;
  }
  
  .paginacao nav .pagination {
    margin: 35px 0;
  }
  
  .paginacao nav .pagination li a {
    background-color: #fff;
    border: 1px solid #e3e3e3;
    color: #666;
    border-radius: 0;
  }
  
  .paginacao nav .pagination li a:hover {
    background-color: #e3e3e3;
  }
  
  .paginacao nav .pagination .active a {
    background-color: #00264a;
    border: 1px solid #00264a;
    color: #fff;
  }



