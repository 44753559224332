.detail-title {
    color: #00264a;
}

.detail-title h1 {
    font-size: 20px;
}

.detail-title h2 {
    font-size: 17px;
    border-bottom: 1px dashed #ccc;
    padding: 20px 0 10px;
    margin-top: 0;
}

.detail-title h2:after {
    content: '';
    width: 30px;
    height: 2px;
    display: table;
    margin-top: 5px;
    background-color: #d2af1c;
}

.detail-list {
    color: #666;
}

.detail-list ul {
    columns: 3;
    line-height: 25px;
}

.detail-list ul li {
    font-size: 15px;
    font-weight: 400;
}

.detail-list ul li .fa {
    margin-right: 5px;
}

@media (max-width: 767px) {
    .detail-list ul {
        columns: 1;
    }
    .detail-list ul li {
        font-size: 14px;
    }
}