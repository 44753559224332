.navegacao {
    position: fixed;
    width: 100%;
    max-width: 350px;
    height: 100%;
    top: 0;
    right: -350px;
    z-index: 3000;
    transition: right .3s;
    background-color: #00264a;
    border-left: 4px solid #d2af1c;
    overflow: auto;
}

.navegacao .topo {
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
}

.navegacao .topo h2 {
    text-align: center;
    margin-top: 20px;
    color: #fff;
    font-size: 20px;
}

.navegacao .topo h2:after {
    content: "";
    display: table;
    margin: 5px auto 0;
    width: 20%;
    height: 2px;
    background-color: #d2af1c;
}

.navegacao .topo h3 {
    padding: 0;
    cursor: pointer;
}

.navegacao ul {
    padding: 5px;
}

.navegacao ul li {
    position: relative;
    float: left;
    width: 50%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    padding: 5px;
    text-align: center;
}

.navegacao ul li a {
    background-color: #1b3c5b;
    height: 100px;
    color: #fff;
    padding: 15px;
    display: flex;
    align-items: center;
}

.navegacao ul li a span {
    margin: 0 auto;
}

.navegacao ul li a span .fa {
    color: #d2af1c;
    display: block;
    margin-bottom: 5px;
    font-size: 20px;
    transform: rotate(-30deg);
}

.navegacao ul li a span .fa-home {
    transform: rotate(0deg);
}

.navegacao ul li a:hover {
    background-color: #d2af1c;
}

.navegacao ul li a:hover .fa {
    color: #00264a;
}

.navegacao h3 {
    color: #e3e3e3;
    font-weight: 500;
    padding: 20px 0 0 0;
    text-align: center;
    clear: both;
    font-size: 16px;
}

.navegacao h3 .fa {
    margin-right: 10px;
}

.navegacao .btn-whatsapp{
    padding: 10px;
    display: flex;
}

.navegacao .btn-whatsapp a {
    border: 0;
    background-color: #1b3c5b;
    height: 50px;
    width: 100%;
    color: #fff;
    text-align: left;
    padding: 15px;    
    font-size: 14px;
}

.navegacao .btn-whatsapp a:hover {
    background-color: #d2af1c;
}

.navegacao .btn-whatsapp a .fa {
    font-size: 20px;
    margin-right: 5px;
}
.navegacao .btn-whatsapp a:hover .fa {
    color: #00264a;
}

.navegacao .area-cliente-nav ul {
    padding: 5px;
}

.navegacao .area-cliente-nav ul li {
    position: relative;
    float: left;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    padding: 5px;
}

.navegacao .area-cliente-nav ul li a {
    background-color: #1b3c5b;
    height: 50px;
    width: 100%;
    color: #fff;
    text-align: left;
    padding: 15px;
}

.navegacao .area-cliente-nav ul li a .fa {
    margin-right: 10px;
    font-size: 20px;
}

.navegacao .area-cliente-nav ul li a:hover {
    background-color: #d2af1c;
}

.open-menu:after {
    content: '';
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 38, 74, 0.6);
    z-index: 2000;
}

.open-menu .navegacao {
    right: 0;
}