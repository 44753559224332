.para-ser-corretor {
    position: relative;
    padding: 50px 0;
    background-image: url("./../../../assets/images/corretores.jpg");
    background-position: top center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
  }
  
  .para-ser-corretor .overlay {
    background-color: #00264a;
    filter: alpha(opacity=60);
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 101;
  }
  
  .para-ser-corretor .wrap {
    position: relative;
    z-index: 201;
    color: #fff;
  }
  
  .para-ser-corretor .wrap .headline h2 {
    color: #fff;
  }
  
  .para-ser-corretor .wrap h3 {
    color: #fff;
  }
  
  .para-ser-corretor .wrap h3 span {
    color: #d2af1c;
  }
  
  .para-ser-corretor .wrap ul {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }
  
  @media (min-width: 768px) {
    .para-ser-corretor .wrap ul {
      float: left;
      width: 58.33333%;
    }
  }
  
  .para-ser-corretor .wrap ul li {
    line-height: 30px;
    padding-left: 20px;
    position: relative;
    font-size: 20px;
  }
  
  .para-ser-corretor .wrap ul li .fa {
    position: absolute;
    left: 0;
    top: 5px;
    color: #d2af1c;
  }
  
  .para-ser-corretor .wrap .realizar-cadastro {
    padding-top: 30px;
    clear: both;
    text-align: center;
  }