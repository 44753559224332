.list-mapa {
    padding: 50px 0;
    margin-right: -15px;
    margin-left: -15px;
    background-color: #f1f1f1;
  }
  
  .list-mapa:before, .list-mapa:after {
    display: table;
    content: " ";
  }
  
  .list-mapa:after {
    clear: both;
  }
  
  .list-mapa .list-imoveis-venda,
  .list-mapa .list-imoveis-aluguel {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }
  
  @media (min-width: 768px) {
    .list-mapa .list-imoveis-venda,
    .list-mapa .list-imoveis-aluguel {
      float: left;
      width: 50%;
    }
  }
  
  .list-mapa .list-imoveis-venda h2,
  .list-mapa .list-imoveis-aluguel h2 {
    color: #666;
    font-weight: 400;
    font-size: 25px;
  }
  
  .list-mapa .list-imoveis-venda h2 span,
  .list-mapa .list-imoveis-aluguel h2 span {
    color: #d2af1c;
    font-weight: 500;
  }
  
  .list-mapa .list-imoveis-venda ul,
  .list-mapa .list-imoveis-aluguel ul {
    border: 1px solid #e3e3e3;
    background-color: #fff;
    padding: 20px 10px;
  }
  
  .list-mapa .list-imoveis-venda ul li,
  .list-mapa .list-imoveis-aluguel ul li {
    display: inline-block;
    margin-left: 5px;
    margin-bottom: 10px;
  }
  
  .list-mapa .list-imoveis-venda ul li a,
  .list-mapa .list-imoveis-aluguel ul li a {
    font-size: 15px;
  }
  