main {
    background-color: #f1f1f1;
}

main .header-detail h1 {
    font-size: 26px;
    color: #00264a;
    line-height: 40px;
}

main .header-detail a {
    background-color: #1a2947;
    color: #fff;
    padding: 5px 10px;
    border-radius: 20px;
    margin-bottom: 10px;
    display: inline-block;
}

main .header-detail h1 address {
    font-size: 16px;
    color: #666;
    font-weight: 400;
    margin: 0;
}

@media (max-width: 991px) {
    main .header-detail h1 {
        font-size: 17px;
        line-height: 25px;
    }
    main .header-detail h1 address {
        font-size: 14px;
    }
}

main .col-left {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    padding-left: 0;
    padding-bottom: 40px;
}

@media (min-width: 768px) {
    main .col-left {
        float: left;
        width: 66.66667%;
    }
}

@media (max-width: 991px) {
    main .col-left {
        position: relative;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
        padding: 0;
    }
}

@media (max-width: 991px) and (min-width: 768px) {
    main .col-left {
        float: left;
        width: 100%;
    }
}

main .col-left .detail-media {
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
}

main .col-left .detail-media .card-imoveis-destaque .item {   
    background-color: #f1f1f1
}   

main .col-left .detail-media .card-imoveis-destaque .item img{
    margin: 0 auto;
    width: initial;
    max-height: 480px;
}

@media (max-width: 991px) {
    main .col-left .detail-media .card-imoveis-destaque .item img{        
        max-height: 236px;
    }
}

main .col-left .detail-media .label-destaque {
    top: 19px;
}

main .col-left .detail-media .tarja {
    top: 10px;
}

main .col-left .detail-media .tab-pane {
    height: 100%;
    max-height: 480px;
}

main .col-left .detail-media .nav-tabs {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #f1f1f1;
}

main .col-left .detail-media .nav-tabs li {
    width: 100%;
    text-align: center;
}

main .col-left .detail-media .nav-tabs li a {
    background-color: #f7f7f7;
    font-size: 16px;
    border: 0;
    border-radius: 0;
    margin: 0;
    padding: 10px 15px;
    color: #666;
}

main .col-left .detail-media .nav-tabs .active a {
    background-color: #fff;
    color: #00264a;
}

main .col-left .detail-icons {
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    padding: 20px 15px;
}

main .col-left .detail-icons ul {
    display: flex;
    justify-content: space-between;
}

main .col-left .detail-icons ul li {
    text-align: center;
    line-height: 25px;
}

/* main .col-left .detail-icons ul li img {
    width: 22px;
    margin-right: 5px; 
} */

main .col-left .detail-icons ul li span {
    display: block;
}

main .col-left .detail-icons .icon-card {    
    width: 23px; 
    height: 23px;
    display: inline-block;
    margin-right: 5px;    
}

main .col-left .detail-icons .bg-icon_preco_imovel {    
    background: url('./../../assets/images/css_sprites.png') -96px -53px;
}

main .col-left .detail-icons .bg-icon_planta_baixa {    
    background: url('./../../assets/images/css_sprites.png') -10px -10px;
}

main .col-left .detail-icons .bg-icon_quarto {    
    background: url('./../../assets/images/css_sprites.png') -96px -10px;
}

main .col-left .detail-icons .bg-icon_suite {    
    background: url('./../../assets/images/css_sprites.png') -10px -96px;
}

main .col-left .detail-icons .bg-icon_banheiro {    
    background: url('./../../assets/images/css_sprites.png') -10px -53px;
}

main .col-left .detail-icons .bg-icon_garagem {    
    background: url('./../../assets/images/css_sprites.png') -53px -53px;
}

main .col-left .detail-icons .bg-icon_qrcode {    
    background: url('./../../assets/images/css_sprites.png') -53px -10px;
}


@media (max-width: 1199px) {
    main .col-left .detail-icons ul {
        display: block;
        columns: 3;
    }
    main .col-left .detail-icons ul li {
        text-align: left;
        line-height: 35px;
    }
    main .col-left .detail-icons ul li span {
        display: initial;
    }
}

@media (max-width: 991px) {
    main .col-left .detail-icons ul {
        columns: 2;
    }
}

@media (max-width: 767px) {
    main .col-left .detail-icons ul {
        columns: 1;
    }
}

main .col-left .detail-icons .panel-hint {
    margin: 15px 0 0;
    font-size: 15px;
    padding: 15px;
    border: 1px solid #fb8c00;
    font-weight: 400;
    color: #666;
    line-height: 20px;
}

main .col-left .detail-icons .panel-hint .fa {
    color: #fb8c00;
    margin-right: 5px;
}

main .col-left .block-detail {
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    padding: 15px 30px;
    margin: 20px 0;
}

main .col-left .block-detail .detail p {
    color: #666;
    font-size: 15px;
    font-weight: 400;
}

main .col-left .interest-points {
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    padding: 15px 30px;
    margin: 20px 0;
}

main .col-left .interest-points .detail-list ul {
    columns: 2;
}

@media (max-width: 767px) {
    main .col-left .interest-points .detail-list ul {
        columns: 1;
    }
    main .col-left .interest-points .detail-list ul li {
        font-size: 14px;
    }
}

main .col-left .block-map {
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    padding: 15px 30px;
    margin: 20px 0;
}

main .col-left .block-map address {
    font-size: 16px;
    color: #666;
    font-weight: 400;
}
main .col-left .block-map .content-map {
    background-image: url('./../../assets/images/imageMap.jpg'); 
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 350px;
    
}

@media (max-width: 991px) {
    main .col-left .block-map {
        padding: 15px 10px;
    }
}

main .col-left .block-video {
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    padding: 15px 30px;
    margin: 20px 0;
}

main .col-left .block-video .detail-title h2 {
    margin-bottom: 20px;
}

main .col-right {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    padding-right: 0;
    padding-bottom: 40px;
}

@media (min-width: 768px) {
    main .col-right {
        float: left;
        width: 33.33333%;
    }
}

@media (max-width: 991px) {
    main .col-right {
        position: relative;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
        padding: 0;
    }
}

@media (max-width: 991px) and (min-width: 768px) {
    main .col-right {
        float: left;
        width: 100%;
    }
}

main .col-right form {
    background-color: #d2af1c;
    padding: 25px;
}

main .col-right form h2 {
    font-size: 20px;
    margin: 0 0 15px;
}

main .col-right form textarea {
    height: 145px;
    margin-bottom: 15px;
}

main .col-right form .botao {
    background-color: #00264a;
    color: #fff;
}

main .imoveis-semelhantes {
    clear: both;
    padding: 40px 0;
    background-color: #e3e3e3;
}

main .imoveis-relacionados {
    padding: 40px 0;
    background-color: #f1f1f1;
}

main .observacao {
    background-color: #fff;
    padding: 20px 0;
}

main .observacao h3 {
    font-size: 13px;
    font-weight: 400;
    text-align: center;
    padding: 0 !important;
    line-height: 18px;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    margin-top: 0;
    margin-bottom: 0;
}

@media (min-width: 768px) {
    main .observacao h3 {
        float: left;
        width: 83.33333%;
    }
}

@media (min-width: 768px) {
    main .observacao h3 {
        margin-left: 8.33333%;
    }
}

main .card-error404 {
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    margin: 50px 0;
    padding: 40px 30px;
}

main .card-error404 h1 .fa{
    font-size: 30px;
    margin-right: 15px;
}

main .card-error404 h1 {
    font-size: 26px;
    color: #00264a;
}

main .card-error404 h2 {
    font-size: 18px;
    color: #666666;
}

main .card-error404 a {
    color: #d2af1c;
}

.detail-form {
    color: #1a2947;
    font-weight: 700;
    font-size: 15px;
    margin-bottom: 8px;
}

.description-wrap {
    white-space: pre-wrap;
}
