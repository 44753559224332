@media (max-width: 991px) {
    .botao span {
        display: none;
    }
}
  
.realizar-cadastro {
    padding-top: 30px;
    clear: both;
    text-align: center;
}
  
.header-cadastre-seu-imovel {
    position: relative;
    height: auto;
    padding: 40px 0;
    background-image: url("./../../assets/images/cadastre-seu-imovel.jpg");
    background-position: top center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}

.header-cadastre-seu-imovel .overlay {
    background-color: #00264a;
    filter: alpha(opacity=60);
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 101;
}

.header-cadastre-seu-imovel .wrap {
    position: relative;
    z-index: 201;
    color: #fff;
}

.header-cadastre-seu-imovel .wrap .col {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    padding-right: 30px;
    padding-top: 35px;
}

@media (min-width: 768px) {
    .header-cadastre-seu-imovel .wrap .col {
        float: left;
        width: 58.33333%;
    }
}

@media (max-width: 991px) {
    .header-cadastre-seu-imovel .wrap .col {
        position: relative;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
    }
}

@media (max-width: 991px) and (min-width: 768px) {
    .header-cadastre-seu-imovel .wrap .col {
        float: left;
        width: 100%;
    }
}

.header-cadastre-seu-imovel .wrap .col h1 {
    margin: 0;
    font-size: 25px;
    line-height: 35px;
}

.header-cadastre-seu-imovel .wrap .col h1 span {
    color: #d2af1c;
}

.header-cadastre-seu-imovel .wrap .col ul {
    padding: 30px 0;
}

.header-cadastre-seu-imovel .wrap .col ul li {
    font-size: 18px;
    line-height: 30px;
    padding-left: 30px;
}

.header-cadastre-seu-imovel .wrap .col ul li .fa {
    color: #d2af1c;
    position: absolute;
    left: 15px;
}

.header-cadastre-seu-imovel .wrap .col p {
    font-size: 16px;
}

.header-cadastre-seu-imovel .wrap .col-form {
    background-color: rgba(255, 255, 255, 0.8);
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    margin-top: 30px;
}

@media (min-width: 768px) {
    .header-cadastre-seu-imovel .wrap .col-form {
        float: left;
        width: 41.66667%;
    }
}

@media (max-width: 991px) {
    .header-cadastre-seu-imovel .wrap .col-form {
        position: relative;
        float: left;
        width: 100%;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
    }
}

.header-cadastre-seu-imovel .wrap .col-form h2 {
    font-size: 21px;
    padding: 15px;
    color: #00264a;
}

.header-cadastre-seu-imovel .wrap .col-form label {
    color: #00264a;
}

.header-cadastre-seu-imovel .wrap .col-form .col-finalidade, .header-cadastre-seu-imovel .wrap .col-form .col-tipo,
.header-cadastre-seu-imovel .wrap .col-form .col-nome, .header-cadastre-seu-imovel .wrap .col-form .col-email,
.header-cadastre-seu-imovel .wrap .col-form .col-fone, .header-cadastre-seu-imovel .wrap .col-form .col-celular {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

@media (min-width: 768px) {
    .header-cadastre-seu-imovel .wrap .col-form .col-finalidade, .header-cadastre-seu-imovel .wrap .col-form .col-tipo,
    .header-cadastre-seu-imovel .wrap .col-form .col-nome, .header-cadastre-seu-imovel .wrap .col-form .col-email,
    .header-cadastre-seu-imovel .wrap .col-form .col-fone, .header-cadastre-seu-imovel .wrap .col-form .col-celular {
        float: left;
        width: 50%;
    }
}

.header-cadastre-seu-imovel .wrap .col-form .col-bairro, .header-cadastre-seu-imovel .wrap .col-form .col-botao {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

@media (min-width: 768px) {
    .header-cadastre-seu-imovel .wrap .col-form .col-bairro, .header-cadastre-seu-imovel .wrap .col-form .col-botao {
        float: left;
        width: 100%;
    }
}

.header-cadastre-seu-imovel .wrap .col-form .col-botao {
    margin-bottom: 20px;
}

.button-whatsapp-seu-imovel {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #0a0a46;
    height: 44px;
    max-width: 221px;
    margin: 0 auto;
    border-radius: 5px;
}

.button-whatsapp-seu-imovel p{
    color: #fff;
}

.auxiliar-text-button-whatsapp{
    text-align: center;
    font-weight: 500;
    font-size: 13pt;
}

@media (max-width: 767px) {
    .header-cadastre-seu-imovel .wrap .col-form .col-botao {
        text-align: center;
    }
}

.vantagens-cadastre-seu-imovel {
    padding: 50px 0;
    background-color: #e3e3e3;
}

.vantagens-cadastre-seu-imovel .headline {
    margin-bottom: 50px;
}

.vantagens-cadastre-seu-imovel .vantagens-col .card {
   min-height: 195px;
}

.relacionamento {
    position: relative;
    padding: 50px 0;
    background-image: url("./../../assets/images/reuniao.jpg");
    background-position: top center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}

.relacionamento .overlay {
    background-color: #00264a;
    filter: alpha(opacity=60);
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 101;
}

.relacionamento .wrap {
    position: relative;
    z-index: 201;
    color: #fff;
}

.relacionamento .wrap .headline h2 {
    color: #fff;
}

.relacionamento .wrap h3 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    color: #fff;
    font-size: 20px;
    line-height: 30px;
    padding: 25px 0;
}

@media (min-width: 768px) {
    .relacionamento .wrap h3 {
    float: left;
    width: 50%;
    }
}

.relacionamento .wrap h3 span {
    color: #d2af1c;
}

.relacionamento .realizar-cadastro {
    text-align: left;
    padding-top: 0;
}

.como-divulgar {
    padding: 50px 0;
}

.como-divulgar h3 {
    text-align: center;
    font-size: 16px;
    line-height: 22px;
    color: #666;
}