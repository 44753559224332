
.quem-somos {
    position: relative;
    height: auto;
    padding: 40px 0;
    background-image: url("./../../assets/images/beira-mar.jpg");
    background-position: top center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}
  
.quem-somos .overlay {
    background-color: black;
    filter: alpha(opacity=60);
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 101;
}
  
.quem-somos .wrap {
    position: relative;
    z-index: 201;
}
  
.quem-somos .wrap .text {
    margin-top: 30px;
}
  
.quem-somos .wrap .text p {
    color: #fff;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 15px;
}
  
.quem-somos .wrap .text p span {
    color: #d2af1c;
    font-weight: 500;
}
  
.quem-somos .wrap h2 {
    color: #fff;
    margin: 30px 0 15px;
    color: #d2af1c;
}
  
.negocios {
    padding: 50px 0;
    background-color: #fff;
}
  
.depoimento {
    background-color: #e3e3e3;
    padding: 50px 0;
}
  
.depoimento .col {
    margin-top: 15px;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    font-size: 16px;
    line-height: 25px;
}
  
@media (min-width: 768px) {
    .depoimento .col {
      float: left;
      width: 50%;
    }
}
  
.depoimento .col p span {
    font-weight: 500;
    color: #00264a;
}
  
.depoimento .cliente {
    padding: 0 15px;
    font-weight: 500;
    color: #00264a;
}
  
.servicos {
    padding: 50px 0;
    background-color: #fff;
}
  
.servicos h3 {
    font-size: 20px;
}
  
.servicos .col {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    padding: 30px 0;
}
  
@media (min-width: 768px) {
    .servicos .col {
      float: left;
      width: 33.33333%;
    }
}
  
.servicos .contato {
    font-size: 20px;
}
  
.servicos .contato span {
    font-size: 25px;
    color: #00264a;
}
  