main .safearea {
    position: relative;
    height: 521px;
    background-image: url("./../../assets/images/bg-home.jpg");
    background-position: top center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

main .safearea .overlay {
    background-color: #00264a;
    filter: alpha(opacity=50);
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 101;
}

main .safearea .wrap {
    padding: 150px 0 0;
    position: relative;
    color: #fff;
    z-index: 201;
    text-align: center;
}

main .safearea .wrap h1 {
    font-size: 40px;
    font-weight: 400;
    letter-spacing: -1px;
    line-height: 45px;
    margin: 0 0 5px;
}

main .safearea .wrap h1 span {
    color: #d2af1c;
}

main .safearea .wrap h2 {
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
    margin: 0 0 20px;
}

main .safearea .wrap form {
    margin-right: -15px;
    margin-left: -15px;
    max-width: 810px;
    margin: 0 auto;
    padding: 10px 5px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 5px;
}

main .safearea .wrap form:before, main .safearea .wrap form:after {
    display: table;
    content: " ";
}

main .safearea .wrap form:after {
    clear: both;
}

main .safearea .wrap form .col2 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    padding: 0 5px;
}

@media (min-width: 768px) {
    main .safearea .wrap form .col2 {
        float: left;
        width: 16.66667%;
    }
}

main .safearea .wrap form .col2 .botao {
    border-color: #d2af1c;
    background-color: #d2af1c;
    color: #fff;
    height: 42px;
    padding: 6px 15px;
    width: 100%;
}

main .safearea .wrap form .col3 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    padding: 0 5px;
}

@media (min-width: 768px) {
    main .safearea .wrap form .col3 {
        float: left;
        width: 25%;
    }
}

main .safearea .wrap form .col5 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    padding: 0 5px;
}

@media (min-width: 768px) {
    main .safearea .wrap form .col5 {
        float: left;
        width: 41.66667%;
    }
}

main .safearea .wrap form .col8 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    padding: 0 5px;    
}

@media (min-width: 768px) {
    main .safearea .wrap form .col8 {
        float: left;
        width: 66.66667%;
    }
}

main .safearea .wrap form .col10 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    padding: 0 5px;
}

@media (min-width: 768px) {
    main .safearea .wrap form .col10 {
        float: left;
        width: 83%;
    }
}

main .safearea .wrap .search-by-code {
    margin-top: 20px;
}

@media (max-width: 991px) {
    main .safearea .wrap {
        padding: 50px 15px;
    }
}

@media (max-width: 767px) {
    main .safearea .wrap {
        padding: 50px 15px 30px;
    }
    main .safearea .wrap h1 {
        font-size: 32px;
        line-height: 35px;
    }
    main .safearea .wrap h2 {
        display: none;
    }
}

main .safearea .link-cadastro-seu-imovel {
    text-align: center;
    position: absolute;
    bottom: 0;
    padding: 15px 0;
    z-index: 201;
    width: 100%;
}

main .safearea .link-cadastro-seu-imovel a {
    color: #fff;
    font-weight: 500;
    font-size: 17px;
    letter-spacing: 2px;
    display: inline-block;
}

main .safearea .link-cadastro-seu-imovel a:hover {
    color: #d2af1c;
}

main .safearea .link-cadastro-seu-imovel a:hover:after {
    width: 100%;
}

main .safearea .link-cadastro-seu-imovel a:after {
    content: "";
    display: table;
    margin: 5px auto 0;
    width: 0;
    height: 2px;
    background-color: #d2af1c;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

main .imoveis-destaque {
    background-color: #e3e3e3;
    padding: 35px 0 0;
}

main .imoveis-destaque .content {
    padding: 65px 10px 50px;
}

main .imoveis-destaque .content .row {
    margin-right: -15px;
    margin-left: -15px;
}

main .imoveis-destaque .content .row:before, main .imoveis-destaque .content .row:after {
    display: table;
    content: " ";
}

main .imoveis-destaque .content .row:after {
    clear: both;
}

main .imoveis-destaque .content .col {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

@media (min-width: 768px) {
    main .imoveis-destaque .content .col {
        float: left;
        width: 25%;
    }
}

main .imoveis-destaque .content .card {
    background-color: #fff;
    padding: 0 20px 20px;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
    font-size: 15px;
}

main .imoveis-destaque .content .card .image-card {
    margin: 0;
    text-align: center;
}

main .imoveis-destaque .content .card .image-card img {
    margin-top: -50px;
}

main .imoveis-destaque .content .card .title-card h3 {
    text-align: center;
    margin: 0;
}

main .imoveis-destaque .content .card .title-card h4 {
    margin-top: 5px;
    font-size: 13px;
    font-weight: 400;
    line-height: 26px;
    border-bottom: 1px solid #e3e3e3;
}

main .imoveis-destaque .content .card .title-card h4 a {
    color: #4b4000;
}

main .imoveis-destaque .content .card .title-card h4 a span {
    font-weight: 500;
}

main .imoveis-destaque .content .card ul li {
    margin-bottom: 4px;
}

main .imoveis-destaque .content .card ul li a:hover {
    color: #d2af1c;
}

main .imoveis-destaque .content .card .tag a{
    background-color: #e3e3e3;
    border-radius: 5px;
    padding: 2px 5px;
    color: #333333;
    font-size: 13px;
}

main .imoveis-destaque .content .card .tag a:hover{   
    color: #333333;    
}

@media (max-width: 991px) {
    main .imoveis-destaque .content {
        overflow-x: auto;
        padding: 65px 15px 50px;
    }
    main .imoveis-destaque .content .row {
        width: 1170px;
    }
}

@media (max-width: 767px) {
    main .imoveis-destaque .content {
        padding: 65px 15px 50px;
    }
    main .imoveis-destaque .content .col {
        position: relative;
        float: left;
        width: 25%;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
    }
}

main .mais-populares {
    padding: 35px 0;
    text-align: center;
    background-color: #f6f6f6;
}

main .mais-populares .polular-search-list {
    margin-top: 40px;
}

main .mais-populares .polular-search-list ul {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

@media (min-width: 768px) {
    main .mais-populares .polular-search-list ul {
        float: left;
        width: 83.33333%;
    }
}

@media (min-width: 768px) {
    main .mais-populares .polular-search-list ul {
        margin-left: 8.33333%;
    }
}

main .empreendimentos {
    padding: 35px 0;
    text-align: center;
    background-color: #e3e3e3;
}

main .vantagens {
    padding: 35px 0;
    background-color: #fff;
}

main .vantagens .nav-tabs {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
}

@media (max-width: 767px) {
    main .vantagens .nav-tabs {
        display: block;
    }
}

main .vantagens .nav-tabs li {
    width: 100%;
    text-align: center;
    background-color: #f1f1f1;
    padding-top: 8px;
}

main .vantagens .nav-tabs li a {
    font-size: 16px;
    background-color: transparent;
    border-color: transparent;
    font-weight: 500;
    color: #666;
    padding: 0 15px;
}

main .vantagens .nav-tabs li:after {
    content: "";
    display: table;
    margin: 5px auto 0;
    width: 0;
    height: 4px;
    background-color: #d2af1c;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

main .vantagens .nav-tabs li:hover:after {
    width: 50%;
}

main .vantagens .nav-tabs .active a {
    color: #00264a;
    border-color: transparent;
    background-color: #f1f1f1;
}

main .vantagens .nav-tabs .active:after {
    width: 50%;
    background-color: #00264a;
}

main .vantagens .tab-content {
    padding: 90px 0 30px;
}

@media (max-width: 767px) {
    main .vantagens .tab-content {
        padding: 30px 0;
    }
}

main .vantagens .tab-content .tab-pane {
    margin-right: -15px;
    margin-left: -15px;
}

main .vantagens .tab-content .tab-pane:before, main .vantagens .tab-content .tab-pane:after {
    display: table;
    content: " ";
}

main .vantagens .tab-content .tab-pane:after {
    clear: both;
}

main .vantagens .tab-content .tab-pane .wrap .vantagens-col .card {
    min-height: 199px;    
}

@media (max-width: 767px) {
    main .vantagens .tab-content .tab-pane .wrap {
        overflow-x: auto;
        display: flex;
        padding: 40px 0 20px;
    }
    main .vantagens .tab-content .tab-pane .wrap .vantagens-col {
        position: relative;
        float: left;
        width: 33.33333%;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
        min-width: 320px;
    }
}

main .vantagens .tab-content .tab-pane .tab-btn {
    text-align: center;
}

main .vantagens .tab-content .tab-pane .tab-btn .botao {
    margin-top: 25px;
}

main .blog {
    padding: 30px 0;
    background-color: #f6f6f6;
    text-align: center;
}

main .blog h2 {
    font-size: 25px;
    margin: 0;
    color: #00264a;
}

main .blog h2 a {
    color: #d2af1c;
}

main .blog .artigos-blog {
    margin-right: -15px;
    margin-left: -15px;
    margin-top: 40px;
}

main .blog .artigos-blog:before, main .blog .artigos-blog:after {
    display: table;
    content: " ";
}

main .blog .artigos-blog:after {
    clear: both;
}

main .blog .artigos-blog .col {
    min-height: 80px !important;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

@media (min-width: 768px) {
    main .blog .artigos-blog .col {
        float: left;
        width: 33.33333%;
    }
}

@media (max-width: 991px) {
    main .blog .artigos-blog .col {
        position: relative;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
    }
}

@media (max-width: 991px) and (min-width: 768px) {
    main .blog .artigos-blog .col {
        float: left;
        width: 100%;
    }
}

main .blog .artigos-blog .col img {
    max-width: 115px;
    position: absolute;
    left: 15px;
}

main .blog .artigos-blog .col .wrap {
    text-align: left;
}

main .blog .artigos-blog .col .wrap h2 {
    font-size: 14px;
    margin: 0;
    margin-left: 130px;
    text-align: left;
}

main .blog .artigos-blog .col .botao {
    background-color: transparent;
    border: 1px solid #00264a;
    color: #00264a;
    border-radius: 0;
    margin: 5px 0 15px 130px;
    padding: 3px 6px;
}

main .blog .artigos-blog .col .botao:hover {
    background-color: #d2af1c;
    color: #fff;
    border: 1px solid #d2af1c;
}