.list-label {
    display: inline-block;
    margin-left: 5px;
    margin-bottom: 10px;
    border-radius: 20px;
    border: 1px solid #ddd;
    padding: 5px 10px;
    background-color: #fff;
}

.list-label:hover {
    border-color: #d2af1c;
    background-color: #d2af1c;
}

.list-label .text {
    font-size: 16px;
    font-weight: 400;
    color: #777;
    line-height: 20px;
}

.list-label .text:hover {
    color: #fff;
}