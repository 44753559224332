.cardImovel {
    background-color: #fff;
    padding: 10px 0;
    margin-bottom: 15px;
    margin-right: -15px;
    margin-left: -15px;
    position: relative;
}

.cardImovel:before, .cardImovel:after {
    display: table;
    content: " ";
}

.cardImovel:after {
    clear: both;
}

.cardImovel .item {   
    background-color: #f3f3f3
}   

.cardImovel .item img{
    margin: 0 auto;
    width: initial;
    max-height: 270px;
}

@media (max-width: 375px) and (min-width: 321px) {
    .cardImovel .item img {
        max-height: 234px;
    }
}

@media (max-width: 320px) {
    .cardImovel .item img {
        max-height: 194px;
    }
}

.cardImovel .img-label-destaque {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    padding: 0 10px;
}

@media (min-width: 768px) {
    .cardImovel .img-label-destaque {
        float: left;
        width: 41.66667%;
    }
}

@media (max-width: 991px) {
    .cardImovel .img-label-destaque {
        position: relative;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
    }
}

@media (max-width: 991px) and (min-width: 768px) {
    .cardImovel .img-label-destaque {
        float: left;
        width: 100%;
    }
}

.cardImovel .wrap {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    padding: 0 10px;
}

@media (min-width: 768px) {
    .cardImovel .wrap {
        float: left;
        width: 58.33333%;
    }
}

@media (max-width: 991px) {
    .cardImovel .wrap {
        position: relative;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
        padding-top: 10px;
    }
}

@media (max-width: 991px) and (min-width: 768px) {
    .cardImovel .wrap {
        float: left;
        width: 100%;
    }
}

.cardImovel .wrap .icon-top, .cardImovel .wrap .icon-bottom {
    position: absolute;
    right: 0;
    padding: 0 15px;
    font-size: 20px;
}

.cardImovel .wrap .icon-top .fa, .cardImovel .wrap .icon-bottom .fa {
    color: #d9534f;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    cursor: pointer;
}

.cardImovel .wrap .icon-top .fa:hover, .cardImovel .wrap .icon-bottom .fa:hover {
    font-size: 23px;
}

@media (max-width: 767px) {
    .cardImovel .wrap .icon-top {
        display: none;
    }
}

.cardImovel .wrap .icon-bottom {
    bottom: 0;
    display: none;
}

@media (max-width: 767px) {
    .cardImovel .wrap .icon-bottom {
        display: block;
    }
}

.cardImovel .wrap h2 {
    font-size: 22px;
    margin: 0 30px 0 0;
}

.cardImovel .wrap h2 a {
    color: #00264a;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

@media (max-width: 767px) {
    .cardImovel .wrap h2 {
        font-size: 16px;
        margin: 0; 
    }
}

.cardImovel .wrap h3 {
    font-size: 14px;
    margin: 0;
    font-weight: 400;
    padding: 5px 0;
}

.cardImovel .wrap h3 a {
    color: #666;
}

@media (max-width: 767px) {
    .cardImovel .wrap h3 {
        font-size: 13px;
    }
}

.cardImovel .wrap ul {
    display: flex;
    justify-content: space-between;
    margin: 10px;
}

.cardImovel .wrap ul li {
    text-align: center;
}

@media (max-width: 767px) {
    .cardImovel .wrap ul li {
        text-align: left;
    }
}

.cardImovel .wrap .icon-card {    
    width: 23px; 
    height: 23px;
    display: inline-block;
    margin-right: 10px;
}

.cardImovel .wrap .bg-icon_preco_imovel {    
    background: url('./../../assets/images/css_sprites.png') -96px -53px;
}

.cardImovel .wrap .bg-icon_planta_baixa {    
    background: url('./../../assets/images/css_sprites.png') -10px -10px;
}

.cardImovel .wrap .bg-icon_quarto {    
    background: url('./../../assets/images/css_sprites.png') -96px -10px;
}

.cardImovel .wrap .bg-icon_suite {    
    background: url('./../../assets/images/css_sprites.png') -10px -96px;
}

.cardImovel .wrap .bg-icon_banheiro {    
    background: url('./../../assets/images/css_sprites.png') -10px -53px;
}

.cardImovel .wrap .bg-icon_garagem {    
    background: url('./../../assets/images/css_sprites.png') -53px -53px;
}

.cardImovel .wrap .bg-icon_qrcode {    
    background: url('./../../assets/images/css_sprites.png') -53px -10px;
}

@media (max-width: 767px) {
    .cardImovel .wrap .icon-card {
        display: none;
    }
}

.cardImovel .wrap ul li span {
    display: block;
}

@media (max-width: 767px) {
    .cardImovel .wrap ul li span {
        display: initial;
    }
}

@media (max-width: 767px) {
    .cardImovel .wrap ul {
        columns: 2;
        display: block;
        line-height: 20px;
    }
}

.cardImovel .wrap .descricao {
    font-size: 14px;
    color: #666;
    font-weight: 400;
}

@media (max-width: 767px) {
    .cardImovel .wrap .descricao {
        display: none;
    }
}

.cardImovel .wrap .valor {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.cardImovel .wrap .valor .botao {
    padding: 8px 7px;
}

.cardImovel .wrap .valor h3 {
    font-size: 25px;
    font-weight: 500;
}

.cardImovel .wrap .valor span button {
    margin-left: 5px;
}

@media (max-width: 767px) {
    .cardImovel .wrap .valor {
        display: block;
        text-align: center;
    }
}

.cardImovel .wrap .codigo h3 {
    color: #666;
    padding: 3px 0;
}

.cardImovel .wrap .codigo h3 span {
    font-weight: 500;
}

.cardImovel:hover {
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
}

.cardImovel:hover .wrap h2 a {
    color: #d2af1c;
}

.cardImovel:hover .wrap .valor {
    color: #d2af1c;
}

.cardImovel:hover .wrap .valor .botao {
    background-color: #d2af1c;
    border: 1px solid #d2af1c;
    color: #fff;
}

.cardImovel-little {
    background-color: #fff;
    padding: 15px;
    border-left: 3px solid #d2af1c;
    margin-bottom: 20px;
}

.cardImovel-little h2 {
    font-size: 20px;
    margin-top: 0px;
    color: #00264a;
}

.cardImovel-little span {
    font-size: 16px;
    color: #666;
}

.cardImovel-little:hover {
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.1);
}

.owl-nav {
    display: flex;
    justify-content: space-between;
    margin-top: 0 !important;
}

.owl-nav .owl-prev, .owl-nav .owl-next {
    position: absolute;
    top: 50%;
    text-align: center;
    width: 40px;
    height: 40px;
    background-color: #d2af1c !important;;
    margin: -17px 4px;
    border-radius: 5px;
}

.owl-nav .owl-prev span, .owl-nav .owl-next span {
    font-size: 27px;
}

.owl-nav .owl-prev:focus, .owl-nav .owl-next:focus {
    outline: none;
}

.owl-nav .owl-prev {
    left: -10px;
}

.owl-nav .owl-next {
    right: -10px;
}

.owl-dots {
    display: none;
}

.label-destaque {
    color: #fff;
    font-size: 14px;
    margin: 0;
    position: absolute;
    z-index: 200;
    left: 25px;
    top: 10px !important;
}

.tarja {
    border-bottom: 20px solid #4caf50;
    border-radius: 5px;
    width: 85px;
    position: absolute;
    z-index: 100;
    left: 21px;
    top: 10px !important;
}

.label-lancamento {
    color: #fff;
    font-size: 14px;
    margin: 0;
    position: absolute;
    z-index: 200;
    left: 25px;
    top: 35px !important;
}

.tarja-lancamento {
    border-bottom: 20px solid red;
    border-radius: 5px;
    width: 105px;
    position: absolute;
    z-index: 100;
    left: 21px;
    top: 35px !important;
}