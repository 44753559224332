.header {
    position: relative;
    height: auto;
    padding: 40px 0;
    background-image: url("./../../assets/images/quero-ser-corretor.jpg");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  @media (max-width: 767px) {
    .header {
      background-size: initial;
    }
  }
  
  .header .overlay {
    background-color: #00264a;
    filter: alpha(opacity=60);
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 101;
  }
  
  .header .wrap {
    position: relative;
    z-index: 201;
    color: #fff;
  }
  
  .header .wrap .col {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    padding-right: 30px;
    padding-top: 35px;
  }
  
  @media (min-width: 768px) {
    .header .wrap .col {
      float: left;
      width: 58.33333%;
    }
  }
  
  @media (max-width: 991px) {
    .header .wrap .col {
      position: relative;
      min-height: 1px;
      padding-right: 15px;
      padding-left: 15px;
    }
  }
  
  @media (max-width: 991px) and (min-width: 768px) {
    .header .wrap .col {
      float: left;
      width: 100%;
    }
  }
  
  .header .wrap .col h1 {
    margin: 0;
    font-size: 25px;
    line-height: 35px;
  }
  
  .header .wrap .col h1 span {
    color: #d2af1c;
  }
  
  .header .wrap .col ul {
    padding: 30px 0;
  }
  
  .header .wrap .col ul li {
    font-size: 18px;
    line-height: 30px;
    padding-left: 30px;
  }
  
  .header .wrap .col ul li .fa {
    color: #d2af1c;
    position: absolute;
    left: 15px;
  }
  
  .header .wrap .col p {
    font-size: 20px;
  }
  
  .header .wrap .col-form {
    background-color: rgba(255, 255, 255, 0.8);
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    margin-top: 30px;
    padding: 15px;
  }
  
  @media (min-width: 768px) {
    .header .wrap .col-form {
      float: left;
      width: 41.66667%;
    }
  }
  
  @media (max-width: 991px) {
    .header .wrap .col-form {
      position: relative;
      min-height: 1px;
      padding-right: 15px;
      padding-left: 15px;
    }
  }
  
  @media (max-width: 991px) and (min-width: 768px) {
    .header .wrap .col-form {
      float: left;
      width: 100%;
    }
  }
  
  .header .wrap .col-form h2 {
    font-size: 21px;
    padding: 15px;
    color: #00264a;
    margin: 0;
  }
  
  .header .wrap .col-form label {
    color: #00264a;
  }
  
  .header .wrap .col-form .col-nome, .header .wrap .col-form .col-email,
  .header .wrap .col-form .col-fone, .header .wrap .col-form .col-celular,
  .header .wrap .col-form .col-dt-nascimento, .header .wrap .col-form .col-bairro,
  .header .wrap .col-form .col-veiculo, .header .wrap .col-form .col-pretensao-salarial {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }
  
  @media (min-width: 768px) {
    .header .wrap .col-form .col-nome, .header .wrap .col-form .col-email,
    .header .wrap .col-form .col-fone, .header .wrap .col-form .col-celular,
    .header .wrap .col-form .col-dt-nascimento, .header .wrap .col-form .col-bairro,
    .header .wrap .col-form .col-veiculo, .header .wrap .col-form .col-pretensao-salarial {
      float: left;
      width: 50%;
    }
  }
  
  .header .wrap .col-form .col-cidade {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }
  
  @media (min-width: 768px) {
    .header .wrap .col-form .col-cidade {
      float: left;
      width: 66.66667%;
    }
  }
  
  .header .wrap .col-form .col-estado {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }
  
  @media (min-width: 768px) {
    .header .wrap .col-form .col-estado {
      float: left;
      width: 33.33333%;
    }
  }
  
  .header .wrap .col-form .col-indicacao, .header .wrap .col-form .col-curriculo, .header .wrap .col-form .col-botao {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }
  
  @media (min-width: 768px) {
    .header .wrap .col-form .col-indicacao, .header .wrap .col-form .col-curriculo, .header .wrap .col-form .col-botao {
      float: left;
      width: 100%;
    }
  } 