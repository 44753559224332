.main {
    background-color: #f1f1f1;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main .error404 {
    background-color: #fff;
    padding: 20px;
    text-align: center;
}

.main .error404 p{
    margin: 0;
    font-size: 20px;
    color: #666666;
}

.main .error404 h1 {
    font-size: 30px;
    color: #00264a;
    margin: 0;
}

.main .error404 h2 {
    font-size: 18px;
    color: #666666;
}

.main .error404 a {
    font-size: 18px;
    color: #d2af1c;
}

.main .error404 .btn-secao-imoveis {
    padding: 10px 15px;
    background-color: #d2af1c;
    color: #fff;
    margin: 20px;
    display: inline-block;
    border-radius: 5px;
}
.main .error404 .secao-imoveis {
   display: block;
   text-align: center;
   margin: 30px 0;
}

