.negocios .col {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    border: 1px solid #f1f1f1;
    padding: 15px;
    min-height: 364px;
    text-align: center;
    margin-top: 15px;
  }
  
  @media (min-width: 768px) {
    .negocios .col {
      float: left;
      width: 33.33333%;
    }
  }
  
  .negocios .col h2 {
    font-size: 20px;
    color: #00264a;
  }
  
  .negocios .col h3 {
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
  }