.botao {
    padding: 11px 15px;
    font-weight: 500;
    font-size: 16px;
    border-radius: 5px;
    color: #fff;
    border: 1px solid #d2af1c;
    background-color: #d2af1c;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.botao:hover {
    color: #fff;
}

.botao:focus {
    outline: none;
}

.azul {
    color: #00264a;
    background-color: transparent;
    border: 1px solid #00264a;
 }

.azul:hover {
    color: #fff;
    background-color: #00264a;
}

.branco {
    color: #fff;
    background-color: transparent;
    border: 1px solid #fff;
}

.branco:hover, .branco:focus {
    color: #666666;
    background-color: #fff;
}