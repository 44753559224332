header {
    margin-right: 25px;
    width: 100%;
}

header .topo {
    background-color: #00264a;
    color: #d2af1c;
}

header .topo .contato a {
    color: #d2af1c;
}

header .topo .contato .icone{
    margin-right: 15px;
}

header .topo .container {
    padding: 10px 15px;
}

@media (max-width: 767px) {
    .topo {
        display: none;
    }
}

header .container {
    display: flex;
    align-items: center;
    padding: 15px;
}

header .col-left {
    display: flex;
    align-items: center;
    padding: 0 !important;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

@media (min-width: 768px) {
    header .col-left {
        float: left;
        width: 50%;
    }
}

@media (max-width: 767px) {
    header .col-left {
        position: relative;
        float: left;
        width: 50%;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
    }
}

header .col-left img {
    width: 150px;
}

header .logomarca h2 {
    display: none;
}

@media (max-width: 991px) {
    header .col-left img {
        width: 120px;
    }
}

header .col-left nav {
    margin-left: 20px;
}

header .col-left nav li {
    font-weight: 500;
    font-size: 16px;
    padding: 11px 15px;
    display: inline-block;
}

header .col-left nav li a {
    color: #00264a;
}

header .col-left nav li a:hover {
    color: #00264a;
}

header .col-left nav li a:hover:after {
    width: 100%;
}

header .col-left nav li a:after {
    content: "";
    display: table;
    margin: 5px auto 0;
    width: 0%;
    height: 2px;
    background-color: #d2af1c;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

@media (max-width: 767px) {
    header .col-left nav li {
        display: none;
    }
}

header .col-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 !important;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

@media (min-width: 768px) {
    header .col-right {
        float: left;
        width: 50%;
    }
}

@media (max-width: 767px) {
    header .col-right {
        position: relative;
        float: left;
        width: 50%;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
    }
}

header .col-right .fav-link a {
    font-weight: 500;
    font-size: 17px;
    color: #00264a;
    position: relative;
}

header .col-right .fav-link a .fav-count {
    border-radius: 2px;
    position: absolute;
    bottom: -3px;
    right: 78px;
    background-color: #0758a6;
    color: #fff;
    font-size: 9px;
    font-weight: 500;
    line-height: 10px;
    min-width: 13px;
    padding: 1px;
    text-align: center;
}

header .col-right .fav-link a:hover {
    color: #00264a;
}

header .col-right .fav-link a .fa-star {
    color: #d2af1c;
    font-size: 17px;
    margin-right: 10px;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    position: absolute;
    left: -27px;
}

header .col-right .fav-link a:hover .fa-star {
    font-size: 20px;
    left: -29px;
}

@media (max-width: 767px) {
    header .col-right .fav-link a .fa-star {
        top: -10px;
    }

    header .col-right .fav-link a .fav-count {
        right: 5px;
        bottom: -10px;
    }
    header .col-right .fav-link a .fav-text {
        display: none;
    }
}

header .col-right .area-cliente .botao {
    margin-left: 20px;
}

@media (max-width: 767px) {
    header .col-right .area-cliente {
        display: none;
    }
}

header .col-right .menu {
    margin-left: 20px;
    border-left: 1px solid #dbdbdb;
    padding-left: 30px;
    min-height: 45px;
}

header .col-right .menu span {
    cursor: pointer;
}

header .col-right .menu .fa {
    color: #00264a;
    font-size: 20px;
    margin-top: 11px;
}

@media (max-width: 991px) {
    header .col-right .menu {
        margin-left: 0;
        border-left: 0;
    }
}

@media (max-width: 767px) {
    header .col-right .menu {
        padding-left: 10px;
    }
    header .col-right .menu .fa {
        margin-top: 15px;
    }
}

.modalContato .modal-content {
    border-radius: 0;
}

.modalContato .modal-header .modal-title{
    font-size: 20px;
    font-weight: inherit;
}

.modalContato .modal-body{
    padding: 20px 15px 30px;
}

.modalContato .modal-body p{
   text-align: center;
   padding: 0 20px;
}

.modalContato .modal-body h2{
    font-size: 17px;
    font-weight: inherit;
    margin-bottom: 0;
    text-align: center;
}

.modalContato .modal-body h2 span{
    color: #d2af1c;
}

.modalContato .modal-body h3{
    text-align: center;
    margin-top: 20px;
    margin-bottom: 0;
}
.modalContato .modal-body h3 a{    
    color: #666666;
}

.modalContato .modal-body h3 a:hover{    
    color: #d2af1c;
}

@media (max-width: 767px) {
    .modalContato .modal-body h1 {
        margin-top: 40px;
    }
}