#cesar-blog{
    padding: 35px 0;
    text-align: center;
}

#cesar-blog h2 a {
    font-size: 25px;
    margin: 0;
    color: #00264a;
}

#cesar-blog h2 a:after {
    content: "";
    display: table;
    margin: 10px auto;
    width: 4%;
    height: 4px;
    background-color: #d2af1c;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

#cesar-blog .artigos-blog {
    display: flex;
    justify-content: space-evenly;
    padding: 30px 0;
}

#cesar-blog .artigos-blog article {
    width: 100%;
    max-width: 360px;
    background: #fff;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

#cesar-blog .artigos-blog article:nth-child(2) {
    margin: 0 10px;
}

#cesar-blog .artigos-blog article img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
}

#cesar-blog .artigos-blog article .wrapper {
    padding: 10px 15px;
    text-align: left;
}

#cesar-blog .artigos-blog article .wrapper a h2 {
    font-size: 17px;
    font-weight: 600;
    color: #00264a;
    transition: .5s;
}

#cesar-blog .artigos-blog article .wrapper a h2:hover {
    color: #d2af1c;
}

#cesar-blog .artigos-blog article .wrapper p {
    line-height: 1.8;
    padding-top: 15px;
    color: #666666;
}

#cesar-blog .artigos-blog article .wrapper a {
    font-weight: 600;
    color: #d2af1c;
}

#cesar-blog .artigos-blog article .wrapper a:hover {
    color: #d2af1c;
}

@media (max-width: 991px) {
    #cesar-blog .artigos-blog {
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    #cesar-blog .artigos-blog article:nth-child(2) {
        margin: 20px 0;
    }
}