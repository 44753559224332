.imoveis-bairro-edificio {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }
  
  @media (min-width: 768px) {
    .imoveis-bairro-edificio {
      float: left;
      width: 50%;
    }
  }

.card-list {
    background-color: #fff;
    padding: 25px;
    min-height: 365px;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.1);
  }
  
  @media (max-width: 767px) {
    .card-list {
      margin-top: 20px;
    }
  }
  
  .card-list h2 {
    font-size: 20px;
    color: #00264a;
  }
  
  .card-list h2 span {
    color: #d2af1c;
  }
  
  @media (max-width: 991px) {
    .card-list h2 {
      font-size: 16px;
    }
  }
  
  .card-list ul li {
    padding: 10px 0;
    border-bottom: 1px solid #e3e3e3;
  }
  
  .card-list ul li:last-child {
    border: none;
  }
  
  .card-list ul li a {
    color: #666;
  }
  
  .card-list ul li a:hover {
    color: #d2af1c;
  }