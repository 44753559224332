.vantagens-col {
    margin-top: 20px;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

@media (min-width: 768px) {
    .vantagens-col {
        float: left;
        width: 33.33333%;
    }
}
.vantagens-col .card {
    background-color: #fff;
    padding: 20px;
    border: 1px solid #ddd;
    text-align: center;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
}

.vantagens-col .card img {
    margin-top: -70px;
}

.vantagens-col .card h2 {
    font-size: 18px;
    color: #666;
    font-weight: 400;
    line-height: 25px;
}