.favoritos {
    background-color: #f1f1f1;
    padding: 50px 0;
}

.favoritos .headline {
    margin-bottom: 30px;
}

.favoritos .row {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

@media (min-width: 768px) {
    .favoritos .row {
        float: left;
        width: 83.33333%;
    }
}

@media (min-width: 768px) {
    .favoritos .row {
        margin-left: 8.33333%;
    }
}

.favoritos .no-favorite {
    background-color: #fff;
    padding: 20px 0;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
}
.favoritos .no-favorite .title {
    text-align: center;
}

.favoritos .no-favorite .title h1 {
    font-size: 25px;
    color: #00264a;
    margin-bottom: 5px;
}

.favoritos .no-favorite .title p {
    font-size: 18px;
    color: #666666;
    margin-bottom: 20px;
}

.favoritos .no-favorite .wrap {
    display: flex;
    justify-content: center;
}

.favoritos .no-favorite .wrap .col-img {
    width: 38%;
    height: 222px;
    background-image:  linear-gradient( to right, white 5%, transparent 80%), url('./../../assets/images/favoritos.png'); 
    background-repeat: no-repeat;
    background-size: contain;
    box-shadow: 10px 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 767px) {
    .favoritos .no-favorite .wrap {
        display: block;
    }

    .favoritos .no-favorite .wrap .col-img {
        width: 60%;
    }
}

@media (max-width: 480px) {
    .favoritos .no-favorite .wrap .col-img {
        width: 90%;
    }
}

.favoritos .no-favorite .wrap .col-text h2 {
    color: #666666;
    font-size: 18px;
    padding: 25px 0 0 25px;
}

.favoritos .no-favorite .wrap .col-text h2 span{
    color: #d9534f;
}

.favoritos .no-favorite .secao-imoveis h2{
    font-size: 18px;
    color: #666666;
}

.favoritos .no-favorite .btn-secao-imoveis {
    padding: 10px 15px;
    background-color: #d2af1c;
    color: #fff;
    margin: 20px;
    display: inline-block;
    border-radius: 5px;
    font-size: 18px;
}
.favoritos .no-favorite .secao-imoveis {
   display: block;
   text-align: center;
   margin: 30px 0;
}