.card-little {
    background-color: #fff;
    padding: 15px;
    border-left: 3px solid #d2af1c;
    margin-bottom: 20px;
  }
  
  .card-little h2 {
    font-size: 20px;
    margin-top: 0px;
    color: #00264a;
  }
  
  .card-little span {
    font-size: 16px;
    color: #666;
  }
  
  .card-little:hover {
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.1);
  }