.form-control {
    height: 42px;
    font-size: 16px;
    font-weight: 400;
    color: #666;
    border: 0;
  }
  
  .form-control:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  }
  
  .form-control:focus {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  }

@media (max-width: 767px) {
    .form-control {
        margin-bottom: 5px;
    }
}

.topo-breadcrumb {
    background-color: #f5f5f5;
    border-bottom: 1px solid #e3e3e3;
}

.topo-breadcrumb .breadcrumb {
    padding: 15px 0;
    margin: 0;
}

.topo-breadcrumb .breadcrumb li a {
    color: #666;
    font-weight: 500;
}

@media (max-width: 767px) {
    .topo-breadcrumb {
        display: none;
    }
    .select2-container {
        margin-bottom: 5px;
    }
}

.select2-container {
    width: 100% !important;
}

.select2-container--default 
.select2-selection--single {
    height: 42px;
    font-size: 16px;
    font-weight: 400;
    padding: 0 10px;
    color: #666;
    border: none;
    border-radius: 5px;
}

.select2-container--default:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  }
  
.select2-container--default .select2-selection--single:focus {
    outline: none !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  }

.select2-container--default .select2-selection--single .select2-selection__rendered{
    color: #666;
    line-height: 44px;
    height: 100%;
    text-align: left;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 7px
}

.select2-container--open .select2-dropdown--below {
    border: none;
}

::-webkit-scrollbar-track {
    background-color: #F4F4F4;
}
::-webkit-scrollbar {
    width: 6px;
    background: #F4F4F4;
}
::-webkit-scrollbar-thumb {
    background: #dad7d7;
}

.ui.segment {
    padding: 0 !important;
    border: 0 !important;
    margin: 0 !important;
}

.swal2-popup .swal2-styled.swal2-confirm {
    display: none !important;
}
.swal2-popup .swal2-styled.swal2-cancel {
    background-color: #bb4641 !important;
}

.leaflet-container {
    height: 481px;
    width: 100%;
    margin: 0 auto;
}

.leaflet-bottom, .leaflet-top{
    z-index: 800;
}