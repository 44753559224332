.vantagens-corretor {
    padding: 50px 0;
    background-color: #fff;
  }
  
  .vantagens-corretor .col {
    margin-top: 20px;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    min-height: 141px;
  }
  
  @media (min-width: 768px) {
    .vantagens-corretor .col {
      float: left;
      width: 50%;
    }
  }
  
  @media (max-width: 991px) {
    .vantagens-corretor .col {
      position: relative;
      min-height: 1px;
      padding-right: 15px;
      padding-left: 15px;
    }
  }
  
  @media (max-width: 991px) and (min-width: 768px) {
    .vantagens-corretor .col {
      float: left;
      width: 100%;
    }
  }
  
  .vantagens-corretor .col img {
    position: absolute;
    width: 60px;
    top: 10px;
  }
  
  .vantagens-corretor .col h2 {
    font-size: 18px;
    color: #00264a;
    padding-left: 75px;
    max-height: 20px;
  }

  @media (max-width: 767px) {
    .vantagens-corretor .col img {
      top: -7px;
    }

    .vantagens-corretor .col h2 {
      font-size: 15px;
    }
  }
  
  .vantagens-corretor .col h3 {
    font-size: 15px;
    margin-top: 35px;
    color: #666;
    font-weight: 400;
    line-height: 20px;
  }
  
  .vantagens-corretor .realizar-cadastro {
    padding-top: 30px;
    clear: both;
    text-align: center;
  }