.carousel-empreendimentos .card-carousel {
    background-color: #f6f6f6;
    margin: 15px;
}

.carousel-empreendimentos .card-carousel a img{
    margin: 0 auto;
    width: auto;
    max-height: 270px;
}

@media (max-width: 480px) {
    .carousel-empreendimentos .card-carousel a img{        
        max-height: 200px;
    }
}

@media (min-width: 768px) {
    .carousel-empreendimentos .card-carousel a img{        
        max-height: 254px;
    }
}

@media (min-width: 992px) {
    .carousel-empreendimentos .card-carousel a img{        
        max-height: 219px;
    }
}

@media (min-width: 1200px) {
    .carousel-empreendimentos .card-carousel a img{        
        max-height: 270px;
    }
}

.carousel-empreendimentos .card-carousel .wrap {
    padding: 15px 0;
    background-color: #fff;
}

.carousel-empreendimentos .card-carousel .wrap h2 {
    font-size: 18px;
    color: #00264a;
    text-align: center;    
    transition: .5s;
}

.carousel-empreendimentos .card-carousel:hover {
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
}

.carousel-empreendimentos .card-carousel:hover .wrap h2 {
    color: #d2af1c;
}

.carousel-empreendimentos .card-carousel:hover .wrap .botao {
    background-color: #d2af1c;
    border: 1px solid #d2af1c;
    color: #fff;
}