.label-360 a {
    position: absolute;
    z-index: 500;
    top: 10px;
    right: 20px;
    background-color: #fff;
    color: #212121;
    border-radius: 5px;
    padding: 5px;
}

.label-360 a:hover {    
    background-color: #f9f7f7;    
}

.label-360 a i {
    font-size: 20px;
}