footer {
    padding: 20px 0 0 0;
    background-color: #00264a;
    color: #fff;
}

footer h4 {
    font-size: 16px;
    margin-bottom: 15px;
    color: #d2af1c;
}

footer .col {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

footer .como-chegar a:link a:visited{
    color:#fff;
}

@media (min-width: 768px) {
    footer .col {
        float: left;
        width: 16.66667%;
    }
}

@media (max-width: 991px) {
    footer .col {
        position: relative;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
    }
}

@media (max-width: 991px) and (min-width: 768px) {
    footer .col {
        float: left;
        width: 33.33333%;
    }
}

footer .col ul li {
    line-height: 28px;
}

footer .col ul li a {
    color: #fff;
}

footer .col ul li a:hover {
    color: #d2af1c;
}

footer .col-contato {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    border-left: 1px solid #334556;
    margin-top: 10px;
}

@media (min-width: 768px) {
    footer .col-contato {
        float: left;
        width: 50%;
    }
}

@media (max-width: 991px) {
    footer .col-contato {
        position: relative;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
        padding: 20px 0 0;
        border-left: none;
        border-top: 1px solid #334556;
    }
}

@media (max-width: 991px) and (min-width: 768px) {
    footer .col-contato {
        float: left;
        width: 100%;
    }
}

footer .col-contato h3 {
    font-size: 15px;
    margin-top: 0;
}

footer .col-contato h3 .fa {
    color: #d2af1c;
    margin-right: 5px;
}

footer .col-contato .horario-funcionamento {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

@media (min-width: 768px) {
    footer .col-contato .horario-funcionamento {
        float: left;
        width: 50%;
    }
}

footer .col-contato .central-atendimento {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    text-align: right;
}

@media (min-width: 768px) {
    footer .col-contato .central-atendimento {
        float: left;
        width: 50%;
    }
}

footer .col-contato .central-atendimento p {
    color: #d2af1c;
    font-size: 25px;
    font-weight: 500;
    margin-left: 15px;
    font-family: 'Merriweather Sans', sans-serif;
}

footer .col-contato .central-atendimento p sup {
    top: -6px;
    font-size: 12px;
}

@media (max-width: 991px) {
    footer .col-contato .central-atendimento {
        text-align: left;
    }
    footer .col-contato .central-atendimento p {
        margin: 0;
    }
}

footer .col-contato .endereco {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    margin-top: 15px;
}

@media (min-width: 768px) {
    footer .col-contato .endereco {
        float: left;
        width: 100%;
    }
}

footer .footer-bottom {
    margin-top: 40px;
    padding: 30px 0;
    border-top: 1px solid #334556;
    margin-bottom: -19px;
}

footer .footer-bottom .col-left {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

@media (min-width: 768px) {
    footer .footer-bottom .col-left {
        float: left;
        width: 41.66667%;
    }
}

footer .footer-bottom .col-left a {
    font-size: 12px;
    color: #fff;
    margin: 0 5px;
    margin-left: 0;
}

footer .footer-bottom .col-left a:hover {
    color: #adabab;
}

footer .footer-bottom .col-center {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

@media (min-width: 768px) {
    footer .footer-bottom .col-center {
        float: left;
        width: 33.33333%;
    }
}

footer .footer-bottom .col-center p {
    font-size: 12px;
    text-align: center;
    margin: 0;
}

footer .footer-bottom .col-right {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    text-align: right;
}

@media (min-width: 768px) {
    footer .footer-bottom .col-right {
        float: left;
        width: 25%;
    }
}

footer .footer-bottom .col-right a {
    color: #fff;
    font-size: 13px;
    padding: 6px 9px;
    border-radius: 50%;
    border: 2px solid #fff;
    margin-right: 5px;
}

footer .footer-bottom .col-right a:hover {
    border-color: #d2af1c;
    background-color: #d2af1c;
}

footer .footer-bottom .col-right .fa-facebook {
    margin: 0 2px;
}

@media (max-width: 991px) {
    footer .footer-bottom .col-left, footer .footer-bottom .col-center {
        position: relative;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
    }
}

@media (max-width: 991px) and (min-width: 768px) {
    footer .footer-bottom .col-left, footer .footer-bottom .col-center {
        float: left;
        width: 50%;
    }
}

@media (max-width: 991px) {
    footer .footer-bottom .col-right {
        padding-top: 25px;
        position: relative;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
    }
}

@media (max-width: 991px) and (min-width: 768px) {
    footer .footer-bottom .col-right {
        float: left;
        width: 100%;
    }
}

@media (max-width: 767px) {
    footer .footer-bottom .col-left, footer .footer-bottom .col-center, footer .footer-bottom .col-right {
        text-align: center;
        position: relative;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
        padding: 10px 0;
    }
}

@media (max-width: 767px) and (min-width: 768px) {
    footer .footer-bottom .col-left, footer .footer-bottom .col-center, footer .footer-bottom .col-right {
        float: left;
        width: 100%;
    }
}
