.list-bairros {
    background-color: #e3e3e3;
    padding: 50px 0;
  }
  
  .list-bairros .container {
    padding: 0;
  }
  
  .list-bairros .busca-bairros {
    padding: 0 15px;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }
  
  @media (min-width: 768px) {
    .list-bairros .busca-bairros {
      float: left;
      width: 66.66667%;
    }
  }
  
  @media (min-width: 768px) {
    .list-bairros .busca-bairros {
      margin-left: 16.66667%;
    }
  }
  
  .list-bairros .busca-bairros h3 {
    font-weight: 400;
    color: #666;
    text-align: center;
  }
  
  .list-bairros .list-card {
    margin-right: -15px;
    margin-left: -15px;
    padding: 40px 15px;
    clear: both;
  }
  
  .list-bairros .list-card:before, .list-bairros .list-card:after {
    display: table;
    content: " ";
  }
  
  .list-bairros .list-card:after {
    clear: both;
  }
  
  .list-bairros .list-card .item {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }
  
  @media (min-width: 768px) {
    .list-bairros .list-card .item {
      float: left;
      width: 25%;
    }
  }
  
  @media (max-width: 1199px) {
    .list-bairros .list-card .item {
      position: relative;
      min-height: 1px;
      padding-right: 15px;
      padding-left: 15px;
    }
  }
  
  @media (max-width: 1199px) and (min-width: 768px) {
    .list-bairros .list-card .item {
      float: left;
      width: 50%;
    }
  }
  
  .list-bairros nav {
    clear: both;
    text-align: center;
  }
  
  .list-bairros nav .pagination {
    margin: 0;
  }
  
  .list-bairros nav .pagination li a {
    background-color: #fff;
    border: 1px solid #e3e3e3;
    color: #666;
    border-radius: 0;
  }
  
  .list-bairros nav .pagination li a:hover {
    background-color: #00264a;
    color: #fff;
  }
  
  .list-bairros nav .pagination .active a {
    background-color: #00264a;
    color: #fff;
  }