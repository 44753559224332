.form-contato {
    position: relative;
    padding: 50px 0;
    background-image: url("./../../assets/images/bg-contato-bairros.jpg");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
.form-contato .overlay {
    background-color: #00264a;
    filter: alpha(opacity=60);
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 101;
  }
  
.form-contato .wrap {
    position: relative;
    z-index: 201;
    color: #fff;
    padding: 20px 0;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }
  
@media (min-width: 768px) {
    .form-contato .wrap {
      float: left;
      width: 58.33333%;
    }
  }
  
.form-contato .wrap h2 {
    font-size: 25px;
    border-left: 3px solid #d2af1c;
    padding-left: 15px;
  }
  
.form-contato .wrap h3 {
    font-size: 18px;
    font-weight: 400;
  }
  
.form-contato .wrap .botao {
    background-color: #d2af1c;
    border: none;
    color: #fff;
  }