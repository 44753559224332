.formas-de-ganhar-dinheiro {
  background-color: #e3e3e3;
  padding: 50px 0;
}

.formas-de-ganhar-dinheiro > h3 {
  text-align: center;
}

.formas-de-ganhar-dinheiro .row {
  margin-right: -15px;
  margin-left: -15px;
  margin-bottom: 25px;
}

.formas-de-ganhar-dinheiro .row:before, .formas-de-ganhar-dinheiro .row:after {
  display: table;
  content: " ";
}

.formas-de-ganhar-dinheiro .row:after {
  clear: both;
}

.formas-de-ganhar-dinheiro .col {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  text-align: center;
}

@media (min-width: 768px) {
  .formas-de-ganhar-dinheiro .col {
    float: left;
    width: 25%;
  }
}

@media (max-width: 991px) {
  .formas-de-ganhar-dinheiro .col {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .formas-de-ganhar-dinheiro .col {
    float: left;
    width: 50%;
  }
}

.formas-de-ganhar-dinheiro .col img {
  width: 60px;
  font-weight: 400;
}

.formas-de-ganhar-dinheiro .col h2 {
  font-size: 14px;
  line-height: 20px;
}