.preco-medio-content {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;

}
  
  @media (min-width: 768px) {
    .preco-medio-content {
      float: left;
      width: 50%;
    }
}

.preco-medio {
    padding: 30px 0;
    background-color: #f1f1f1;
  }
  
  .preco-medio .preco-medio-content {
    margin-top: 20px;
  }
  
  .preco-medio .card-list {
    min-height: 200px;
    padding: 10px 25px;
  }
  
  .preco-medio .card-list h2 {
    font-size: 18px;
    text-align: center;
    line-height: 30px;
    color: #00264a;
  }
  
  .preco-medio .card-list h2 span {
    color: #d2af1c;
    display: block;
    font-size: 30px;
  }
  
  .preco-medio .card-list h3 {
    margin: 0;
    font-size: 14px;
    text-align: center;
    color: #666;
    font-weight: 400;
    margin-bottom: 15px;
  }